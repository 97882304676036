/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import dataOne from "@components/pageHome/data"
import DescriptionOfert from "@components/pagePostulacion/components/descriptionOfert"

const StructureCulqui = ({ location, data }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div>
      <BannerCookies />
      <Header
        path="/nosotros/cultura-agile/"
        location={location}
        windowsWidth={windowsWidth}
      />
      <section className="container">
        <DescriptionOfert location={location} data={data} />
        <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </section>
    </div>
  )
}

export default StructureCulqui
