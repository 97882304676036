import React, { useState, useEffect } from "react"
import cargar from "@components/pagePostulacion/image/insert/cargar.svg"
import error from "@components/pagePostulacion/image/insert/error.svg"
import listo from "@components/pagePostulacion/image/insert/listo.svg"
import hexagonoAmarillo from "@components/pagePostulacion/image/hexagonoAmarillo.png"
import hexagonoAzul from "@components/pagePostulacion/image/hexagonoAzul.png"
import hexagonoAzulOscuro from "@components/pagePostulacion/image/hexagonoAzulOscuro.png"
import Recaptcha from "react-google-recaptcha"
import { Link } from "gatsby"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"

// eslint-disable-next-line no-undef
const KEY_CAPTCHA = process.env.KEY_CAPTCHA

const Start = ({ location, data }) => {
  const [imageInput, setImageInput] = useState(cargar)
  const [textInput, setTextInput] = useState("Adjunta tu archivo aquí")
  const [classInput, setClassInput] = useState("class-cargar")
  const [pdfAttached, setPDFAttached] = useState(null)
  const [valueCaptcha, setValueRecaptcha] = useState(null)

  const onChangeFile = e => {
    const file = e.target.files[0]
    if (file.type == "application/pdf") {
      if (file.size <= 1048576) {
        // 1MB en bytes
        setImageInput(listo)
        setTextInput(
          <>
            Archivo listo <br />
            {file.name}{" "}
          </>
        )
        setClassInput("class-listo")
        setPDFAttached(file)
      } else {
        // El archivo excede el tamaño máximo permitido
        setImageInput(error)
        setTextInput(
          <>
            El archivo no se logró subir correctamente. <br />
            Por favor, vuelva a intentarlo. <br />
            Recuerda subir tu archivo en formato PDF tamaño máximo 1MB.
          </>
        )
        setClassInput("class-error")
      }
    } else {
      setImageInput(error)
      setTextInput(
        <>
          El archivo no se logró subir correctamente. <br /> Por favor, vuelva a
          intentarlo{" "}
        </>
      )
      setClassInput("class-error")
    }
  }

  const [isVerified, setIsVerified] = useState(false)
  const handleRecaptchaVerify = response => {
    if (response) {
      setIsVerified(true)
      setValueRecaptcha(response)
    } else {
      setIsVerified(false)
    }
  }

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  const onSubmitCV = async e => {
    e.preventDefault()
    setSpin(true)
    try {
      const formData = new FormData()
      formData.append("landing_postulation", data.keycargo) // Valores aceptados: middle_backend_developer, middle_frontend_developer, fullstack_developer_php, general
      formData.append("g-recaptcha-response", valueCaptcha)
      formData.append("booking_attachment", pdfAttached)

      // const uri = "http://localhost"
      const uri = ""
      const response = await fetch(`${uri}/backend/postulate.php`, {
        method: "POST",
        body: formData,
      })
      const statusText = response.statusText
      if (statusText === "FAILED_VALIDATION_CAPTCHA") {
        setImageInput(error)
        setTextInput(
          <>
            No se pudo validar el captcha <br /> Por favor, refresque y vuelva a
            intentarlo{" "}
          </>
        )
        setClassInput("class-error")
      } else {
        setSpin(false)
        setImageInput(null)
        setTextInput(
          <>
            ¡Gracias por tu postulación! <br />
            💛{" "}
          </>
        )
        setClassInput("class-enviado")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true)

  useEffect(() => {
    if (pdfAttached && isVerified) {
      setBotonDeshabilitado(false)
    }
  }, [pdfAttached, isVerified])

  return (
    <div className="container-descriptionOfert-postulacion">
      <div className="container-hexagonos-description-ofert">
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoOne"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoTwo"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoThree"
        />
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoFour"
        />
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoFive"
        />
        <img src={hexagonoAzul} alt="icon-hexagono" className="hexagonoSix" />
        <img
          src={hexagonoAmarillo}
          alt="icon-hexagono"
          className="hexagonoSeven"
        />
        <img
          src={hexagonoAzulOscuro}
          alt="icon-hexagono"
          className="hexagonoEight"
        />
      </div>
      <p className="title-share-postulacion">{data.puesto}</p>
      <div className="container-insert-and-description-postulacion">
        <div>
          <div className="btn-return return-agile">
            <Link
              to={`/jobs-beex/${location.search}`}
              className="link-to-return"
              aria-label="enlace"
            >
              <span className="flecha-izquierda">
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L1 5M1 5L5.09091 9M1 5L5.09091 0.999999"
                    stroke="#2C5C82"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Regresar
            </Link>
          </div>
          <div className="container-button-insert-and-description">
            <div className="button-insert-and-description">{data.area}</div>
            <div className="button-insert-and-description">
              {" "}
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_12292_750)">
                  <path
                    d="M6.11544 11.6778L8.76457 9.69094V5.64372C8.76457 5.23677 9.09351 4.90784 9.50045 4.90784C9.90739 4.90784 10.2363 5.23677 10.2363 5.64372V10.0589C10.2363 10.2907 10.1274 10.5093 9.94198 10.6476L6.99853 12.8552C6.86609 12.9546 6.71153 13.0024 6.55775 13.0024C6.33331 13.0024 6.11255 12.9016 5.9683 12.7073C5.72394 12.3827 5.79018 11.9214 6.11544 11.6778Z"
                    fill="#60636D"
                  />
                  <path
                    d="M9.5 0.5C14.7386 0.5 19 4.76138 19 10C19 15.2386 14.7386 19.5 9.5 19.5C4.26138 19.5 0 15.2386 0 10C0 4.76138 4.26138 0.5 9.5 0.5ZM9.5 18.0283C13.9262 18.0283 17.5283 14.4262 17.5283 10C17.5283 5.57378 13.9262 1.97172 9.5 1.97172C5.07304 1.97172 1.47172 5.57378 1.47172 10C1.47172 14.4262 5.07378 18.0283 9.5 18.0283Z"
                    fill="#60636D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_12292_750">
                    <rect
                      width="19"
                      height="19"
                      fill="white"
                      transform="matrix(-1 0 0 1 19 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {data.time}
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
        <form encType="multipart/form-data">
          <div>
            <div className="container-insert-pdf">
              {classInput === "class-enviado" ? null : (
                <>
                  <p className="container-insert-pdf-title">
                    Adjunta tu CV aquí
                  </p>
                  <p className="container-insert-pdf-text">
                    Recuerda subir tu archivo en formato PDF con tamaño máximo
                    1MB.
                  </p>
                </>
              )}

              <div>
                <label className={`container-insert-pdf-banner ${classInput}`}>
                  <img src={imageInput} alt="" />
                  <span className="container-insert-pdf-banner-text">
                    {textInput}
                  </span>
                  <input
                    className="FileUpload1"
                    id="FileInput"
                    name="booking_attachment"
                    type="file"
                    accept="application/pdf"
                    onChange={onChangeFile}
                  />
                </label>
              </div>
              {classInput === "class-enviado" ? null : (
                <>
                  <div>
                    <Recaptcha
                      sitekey={KEY_CAPTCHA} // ESTO DEBE IR EN .env
                      onChange={handleRecaptchaVerify}
                    />
                  </div>
                </>
              )}
              {classInput === "class-enviado" ? null : (
                <>
                  <br />{" "}
                  <button
                    className={
                      botonDeshabilitado
                        ? "btn-start-desabilitado"
                        : "btn-start-postulacion"
                    }
                    onClick={onSubmitCV}
                    disabled={botonDeshabilitado}
                  >
                    Aplicar ahora
                    {spin ? (
                      <Loader
                        type="Oval"
                        color="#5e5e5e"
                        height={20}
                        width={20}
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Start
